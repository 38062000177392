import { computed, signal } from '@preact/signals-react';

export const AUTH_TOKEN_KEY = '__orderbook_auth_token__';

const __token = signal<undefined | null | string>(undefined);

export const setToken = (token: string | null) => {
  token && localStorage.setItem(AUTH_TOKEN_KEY, token);
  __token.value = token;
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  __token.value = token;
  return token;
};

export const clearToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  __token.value = null;
};

export const sToken = computed(() => {
  if (__token.value === undefined) {
    const token = getToken();
    if (token && !isTokenExpired(token)) {
      __token.value = token;
    } else {
      clearToken();
    }
  }
  return __token.value;
});

export const sDecodedToken = computed(() => {
  const token = sToken.value;
  return parseJwt(token);
});

export function parseJwt(jwt?: string | null) {
  if (!jwt) return {};
  const payload = jwt.split('.')[1];
  const decodedPayload = Buffer.from(payload, 'base64').toString();
  return JSON.parse(decodedPayload);
}

export const sAvailableMarketplaces = computed(() => {
  const token = getToken();
  if (!token) return [];
  const { marketplaces } = parseJwt(token);
  return marketplaces as Array<string>;
});

function isTokenExpired(token: string) {
  const { exp } = parseJwt(token);
  return Math.floor(Date.now() / 1000) > exp;
}
