import { ElementType, PropsWithChildren } from 'react';
import { FieldValues, get } from 'react-hook-form';
import { classes } from '@app/components';
import { InputFieldWithFormProps } from './types';

export function InputFormField<
  Type extends FieldValues,
  ElType extends ElementType,
>({
  path,
  label,
  instruction,

  reactHookForm,
  registerOptions,

  as,
  children,
  disabled,
  readOnly,

  className,
  errorClassName = classes.error,
  inputClassName = classes.input,
  labelClassName = classes.label,

  ...inputProps
}: PropsWithChildren<InputFieldWithFormProps<Type, ElType>>) {
  const error = get(reactHookForm.formState.errors, path);

  const Input = as ?? 'input';

  return (
    <div className={className}>
      <label className={labelClassName}>
        <p>{label}</p>
        <div className='opacity-80 text-xs'>{instruction}</div>

        {children ?? (
          <Input
            readOnly={readOnly}
            disabled={disabled}
            className={inputClassName}
            {...reactHookForm.register(path, registerOptions)}
            {...inputProps}
          />
        )}
      </label>
      {error?.message && (
        <p className={errorClassName}>{error?.message as string}</p>
      )}
    </div>
  );
}
