import React from 'react';
import classNames from 'classnames';

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  size?: keyof typeof sizes;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  onMouseDown?: () => void;
  variant?: keyof typeof variants;
  href?: string;
  target?: string;
  loading?: boolean;
  width?: string;
}

const baseClasses = `m-0 items-center justify-center rounded-[2rem] font-semibold whitespace-nowrap`;

const sizes = {
  normal: 'text-xl px-5 py-3',
  small: 'text-md px-3 py-2',
  xsmall: 'text-xs px-2 py-2',
};

const variants = {
  dangerous: {
    enabled:
      'text-red-500 border border-gray-300 cursor-pointer hover:bg-red-800 hover:text-white',
    disabled: 'text-gray-400 border border-gray-200',
  },
  exciting: {
    enabled:
      'text-white bg-green-500 uppercase cursor-pointer hover:bg-green-800',
    disabled: 'bg-[#bbb] text-white uppercase',
  },
  primary: {
    enabled: 'bg-black text-white uppercase cursor-pointer hover:bg-black/80',
    disabled: 'bg-[#bbb] text-white uppercase',
  },
  secondary: {
    enabled:
      'text-black border border-gray-300 cursor-pointer hover:bg-gray-200',
    disabled: 'text-gray-400 border border-gray-200',
  },
};

export const Button: React.FC<ButtonProps> = ({
  type = 'button',
  children,
  className,
  disabled,
  onClick,
  onMouseDown,
  variant = 'primary',
  size = 'small',
  href,
  target = '_blank',
  width = 'w-full',
  loading,
}) => {
  const buttonClasses = classNames(
    baseClasses,
    variants[variant][disabled ? 'disabled' : 'enabled'],
    width,
    sizes[size],
    className,
  );

  const content = loading ? (
    <div className='flex items-center justify-center w-full h-6 gap-3'>
      <div className='flex flex-row animate-pulse items-center'>
        <div
          className='inline-block text-blue-500 w-6 h-6 border-4 border-blue-800 text-xs align-middle text-center rounded-full animate-spin'
          role='status'
        >
          ↻
        </div>
      </div>
    </div>
  ) : (
    children
  );

  if (href) {
    return (
      <a
        href={href}
        className={buttonClasses}
        rel='noopener noreferrer'
        target={target}
      >
        {content}
      </a>
    );
  }

  return (
    <button
      type={type}
      className={buttonClasses}
      disabled={disabled || loading}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {content}
    </button>
  );
};
