import { Fragment, useMemo } from 'react';
import type { FieldValues, Path } from 'react-hook-form';

import type { ArrayFieldProps, GetArrayPaths, GetPathTypeReturn } from '.';
import { InputFieldWithFormProps } from '../types';
// import type { InputFieldWithFormProps } from '..';

const getPathKey =
  <Type extends FieldValues, RootType extends Path<Type>, NextSegment>(
    collectionPath: RootType,
    index: number,
  ) =>
  (path?: NextSegment) =>
    `${collectionPath}.${index}${
      path ? `.${path.toString()}` : ''
    }` as GetPathTypeReturn<Type, RootType, NextSegment>;

export function ArrayField<
  Type extends FieldValues,
  PathType extends GetArrayPaths<Type>,
>({
  path: collection,
  render,
  ...props
}: InputFieldWithFormProps<
  Type,
  typeof Fragment,
  ArrayFieldProps<Type, PathType>
>) {
  const {
    reactHookForm: { watch },
  } = props;

  const values = watch(collection);

  if (!render) {
    console.error('missing render function in <Array>');
    return <></>;
  }

  return (
    <>
      {[...Array(values?.length ?? 0)].map((_, index) => (
        <Slice index={index} path={collection} render={render} key={index} />
      ))}
    </>
  );
}

function Slice<Type extends FieldValues, PathType extends GetArrayPaths<Type>>({
  index,
  path,
  render,
}: Pick<ArrayFieldProps<Type, PathType>, 'path' | 'render'> & {
  index: number;
}) {
  const renderProps = useMemo<Parameters<typeof render>[0]>(() => {
    return {
      path: getPathKey(path, index),
      index,
      collection: path,
      element: getPathKey(path, index)() as GetPathTypeReturn<
        Type,
        PathType,
        undefined
      >,
    };
  }, [path, index]);

  return <>{render(renderProps)}</>;
}
