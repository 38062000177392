import { ElementType, PropsWithChildren, useCallback } from 'react';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { isDate } from 'date-fns';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { InputFieldWithFormProps } from './types';
import { InputFormField } from './InputFormField';
import { classes } from '@app/components';

// import { classes, InputFormField, InputFieldWithFormProps } from '.';

export function DateFormField<
  Type extends FieldValues,
  ElType extends ElementType,
>(props: PropsWithChildren<InputFieldWithFormProps<Type, ElType>>) {
  const {
    reactHookForm: { setValue, watch },
    path,
  } = props;

  const value = watch(path);

  const handleChange = useCallback(
    (value: Date | null | undefined) => {
      if (value && isDate(value)) {
        setValue(
          path,
          getServerTimestamp(value.getTime()) as PathValue<Type, Path<Type>>,
          { shouldValidate: true },
        );
      }
    },
    [path, setValue],
  );

  return (
    <InputFormField {...props} className={`${classes.timeInput}`}>
      <DatePicker
        className={classes.input}
        dateFormat='MMMM d, yyyy h:mm aa'
        disabled={props.disabled}
        onChange={handleChange}
        selected={value ? getDefaultDateTime(value) : undefined}
        showTimeInput
        todayButton='Now'
      />
    </InputFormField>
  );
}

function getServerTimestamp(timestamp: number) {
  return Math.floor(timestamp / 1000).toString();
}

function getDefaultDateTime(timestamp: string) {
  const date = new Date(parseInt(timestamp) * 1000);
  if (timestamp === '18446744011573954816') {
    // OLD COMMENT the server returns '18446744011573954816' when the timestamp is unset
    return new Date();
  }
  return new Date(parseInt(timestamp) * 1000);
}
