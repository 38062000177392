import { FieldValues } from 'react-hook-form';
import { PropsWithChildren } from 'react';
import { InputFieldWithFormPropsAndNativeElementProps } from './types';
import { InputFormField } from './InputFormField';
import { classes } from '@app/components';

export function CheckboxFormField<Type extends FieldValues>({
  className,
  labelClassName,
  ...props
}: PropsWithChildren<
  InputFieldWithFormPropsAndNativeElementProps<Type, 'input'>
>) {
  return (
    <InputFormField
      className={className ?? `${classes.input} whitespace-nowrap w-fit`}
      inputClassName={classes.fit}
      labelClassName={
        labelClassName ??
        `${classes.label} flex flex-row-reverse gap-2 items-center justify-end`
      }
      type='checkbox'
      {...props}
    />
  );
}
