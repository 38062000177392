import { Turnstile } from '@marsidev/react-turnstile';
import { useCallback } from 'react';

const TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY;

if (!TURNSTILE_SITE_KEY) {
  throw new Error('must supply NEXT_PUBLIC_TURNSTILE_SITE_KEY');
}

export type LBCaptchaToken = {
  captchaToken: string;
};

export type LBCaptchaProps = {
  onVerify(token: LBCaptchaToken): void;
};

export function LBCaptcha({ onVerify }: LBCaptchaProps) {
  const handleSuccess = useCallback(
    (captchaToken: string) => {
      onVerify({ captchaToken });
    },
    [onVerify],
  );

  if (TURNSTILE_SITE_KEY) {
    return (
      <Turnstile
        options={{ theme: 'light' }}
        siteKey={TURNSTILE_SITE_KEY}
        onSuccess={handleSuccess}
      />
    );
  } else {
    throw new Error('must supply NEXT_PUBLIC_TURNSTILE_SITE_KEY');
  }
}
