import _classes from './FormFields.module.css';

export const classes = _classes as {
  error: string;
  fit: string;
  input: string;
  label: string;
  labelInline: string;
  timeInput: string;
};
