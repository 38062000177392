import { ChangeEvent, PropsWithChildren, useCallback, useEffect } from 'react';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { classes } from '@app/components';
import { InputFieldWithFormPropsAndNativeElementProps } from './types';
import { InputFormField } from './InputFormField';

export function SelectFormField<Type extends FieldValues>({
  children,
  className,
  labelClassName,
  defaultValue,
  ...props
}: PropsWithChildren<
  InputFieldWithFormPropsAndNativeElementProps<Type, 'select'>
>) {
  const {
    reactHookForm: { getValues, setValue, watch },
    path,
  } = props;

  const value = watch(path);
  useEffect(() => {
    !value &&
      defaultValue &&
      setValue(path, defaultValue as PathValue<Type, Path<Type>>);
  }, [defaultValue, value, path, setValue]);

  const handleChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
      setValue(path, value as PathValue<Type, Path<Type>>);
    },
    [path, setValue],
  );

  return (
    <InputFormField {...props} className={`${classes.timeInput}`}>
      <select
        disabled={props.disabled}
        className={classes.input}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        {children}
      </select>
    </InputFormField>
  );
}
