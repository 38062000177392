import { FieldValues, Path, UseFormReturn, useForm } from 'react-hook-form';
import { ElementType, ReactElement, useMemo } from 'react';

import { InputWithTranslationsFormField } from './InputWithTranslationsFormField';
import {
  AddElementField,
  AddElementFieldProps,
  ArrayField,
  ArrayFieldProps,
  DeleteElementField,
  DeleteElementFieldProps,
  FormInputRenderProps,
  GetArrayPaths,
} from './inputArray';
import { InputFormField } from './InputFormField';
import { SelectFormField } from './SelectFormField';
import { InputFieldWithNativeElementProps as WithBrowserElementProps } from './types';
import { CheckboxFormField } from './CheckboxFormField';
import { DateFormField } from './DateFormField';
import { ImagePreviewField } from './ImagePreviewField';

export type UseFormWithInputsProps = {
  disabled?: boolean;
};

export function useFormWithInputs<Type extends FieldValues>(
  useFormProps: Parameters<typeof useForm<Type>>[0],
  inputProps?: UseFormWithInputsProps,
) {
  const form = useForm<Type>(useFormProps);

  return useMemo(() => {
    return {
      ...form,
      ...wrapInputsWithForm<Type>(form, inputProps),
    };
  }, [form, inputProps?.disabled]);
}

function wrapInputsWithForm<Type extends FieldValues>(
  form: UseFormReturn<Type>,
  inputProps: UseFormWithInputsProps = {},
) {
  const inputs = {
    Checkbox: (props: WithBrowserElementProps<Type, 'input'>) => (
      <CheckboxFormField reactHookForm={form} {...inputProps} {...props} />
    ),

    DateField: <ElType extends ElementType = 'input'>(
      props: WithBrowserElementProps<Type, ElType>,
    ) => <DateFormField reactHookForm={form} {...inputProps} {...props} />,

    // FileUpload: (props: FileUploadFieldProps<Type>) => (
    //   <FileUploadField reactHookForm={form} {...inputProps} {...props} />
    // ),

    ImagePreview: <ElType extends ElementType = 'input'>(
      props: WithBrowserElementProps<Type, ElType>,
    ) => <ImagePreviewField reactHookForm={form} {...inputProps} {...props} />,

    InputField: <ElType extends ElementType = 'input'>(
      props: WithBrowserElementProps<Type, ElType>,
    ) => <InputFormField reactHookForm={form} {...inputProps} {...props} />,

    SelectField: (props: WithBrowserElementProps<Type, 'select'>) => (
      <SelectFormField reactHookForm={form} {...inputProps} {...props} />
    ),

    InputWithTranslationsField: <ElType extends ElementType = 'input'>(
      props: WithBrowserElementProps<Type, ElType>,
    ) => <InputWithTranslationsFormField reactHookForm={form} {...props} />,
  };

  const arrayModifiers = {
    Add: (props: AddElementFieldProps<Type>) => (
      <AddElementField reactHookForm={form} {...inputProps} {...props} />
    ),

    Delete: (props: DeleteElementFieldProps<Type>) => (
      <DeleteElementField reactHookForm={form} {...inputProps} {...props} />
    ),
  };

  return {
    Array: <T extends GetArrayPaths<Type>>(props: ArrayFieldProps<Type, T>) => (
      <ArrayField reactHookForm={form} {...inputProps} {...props} />
    ),

    ...arrayModifiers,
    ...inputs,
  };
}

export type FormInputs<Type extends FieldValues> = ReturnType<
  typeof wrapInputsWithForm<Type>
>;

export type ArrayHelper<
  RequiredFields extends keyof FormInputs<FieldValues>,
  RequiredRenderValues extends keyof FormInputRenderProps<
    FieldValues,
    Path<FieldValues>
  >,
> = <Type extends FieldValues, PathType extends GetArrayPaths<Type>>(
  props: Pick<FormInputs<Type>, RequiredFields> &
    Pick<FormInputRenderProps<Type, PathType>, RequiredRenderValues>,
) => JSX.Element | ReactElement;

export type ArrayPartial<
  RequiredFields extends keyof FormInputs<Type>,
  RequiredRenderValues extends keyof FormInputRenderProps<Type, PathType>,
  Type extends FieldValues = FieldValues,
  PathType extends GetArrayPaths<Type> = GetArrayPaths<Type>,
> = (
  props: Pick<FormInputs<Type>, RequiredFields> &
    Pick<FormInputRenderProps<Type, PathType>, RequiredRenderValues>,
) => JSX.Element | ReactElement;
