import { Fragment, ReactNode, useCallback } from 'react';
import { FieldValues, Path, PathValue } from 'react-hook-form';
import { InputFieldProps, InputFieldWithFormProps } from '../types';
import { InputFormField } from '../InputFormField';

export type AddElementFieldProps<Type extends FieldValues> = InputFieldProps<
  Type,
  typeof Fragment
> & {
  render: (
    props: Pick<InputFieldProps<Type, typeof Fragment>, 'disabled'> & {
      onClick(): void;
    },
  ) => ReactNode;
  pathToFirstField?: string;
};

export function AddElementField<Type extends FieldValues>(
  props: InputFieldWithFormProps<
    Type,
    typeof Fragment,
    AddElementFieldProps<Type>
  >,
) {
  const {
    path,
    render,
    reactHookForm: { getValues, setValue, setFocus },
    pathToFirstField,
  } = props;

  const handleClick = useCallback(() => {
    const length = getValues(path as Path<Type>)?.length ?? (0 as number);

    if (pathToFirstField) {
      const pathToInput = `${path}.${length}.${pathToFirstField}`;
      setTimeout(() => {
        setFocus(pathToInput as any, { shouldSelect: true });
      }, 1);
    }

    setValue(
      `${path}.${length}` as Path<Type>,
      undefined as PathValue<Type, Path<Type>>,
      {
        shouldTouch: true,
      },
    );
  }, [getValues, path, pathToFirstField, setFocus, setValue]);

  return (
    <InputFormField {...props}>
      {render({ onClick: handleClick, ...props })}
    </InputFormField>
  );
}
