import { useState, useEffect, useRef } from 'react';
import { ElementType, PropsWithChildren } from 'react';
import { FieldValues, get, useWatch } from 'react-hook-form';
import classnames from 'classnames';
import { primaryLanguage, supportedLanguages } from '@lib';
import { classes } from '@app/components';
import { InputFieldWithFormProps } from './types';

export function InputWithTranslationsFormField<
  Type extends FieldValues,
  ElType extends ElementType,
>({
  path,
  label,
  instruction,
  characterLimit,
  reactHookForm,
  registerOptions,
  as,
  disabled,
  readOnly,
  className,
  errorClassName = classes.error,
  inputClassName = classes.input,
  labelClassName = classes.label,
  languages = [...supportedLanguages],
  ...inputProps
}: PropsWithChildren<InputFieldWithFormProps<Type, ElType>> & {
  languages?: string[];
}) {
  const pathToPrimaryLang = `${path}.${primaryLanguage}`;
  const error = get(reactHookForm.formState.errors, pathToPrimaryLang);
  const [active, setActive] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const rootRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const toggleActive = () => {
      if (rootRef.current && rootRef.current.contains(document.activeElement)) {
        setActive(true);
      } else {
        setTimeout(() => {
          setActive(false);
        }, 0);
      }
    };

    document.addEventListener('focusin', toggleActive);
    return () => {
      document.removeEventListener('focusin', toggleActive);
    };
  }, []);

  const Input = as ?? 'input';
  const __languages = [...languages.filter((l) => l !== primaryLanguage)];

  const description = useWatch({ control: reactHookForm.control, name: path });
  useEffect(() => {
    setCharacterCount(description?.en?.length || 0);
  }, [description]);

  return (
    <div
      ref={rootRef}
      className={classnames(
        className,
        active ? 'p-3 border bg-gray-100 rounded-md' : '',
      )}
    >
      <label className={labelClassName}>
        <p>{label}</p>
        <div className='flex justify-between'>
          <div className='opacity-80 text-xs'>{instruction}</div>
          {characterLimit && (
            <div
              className={classnames(
                characterCount > characterLimit && 'text-red-600',
              )}
            >
              {characterCount}/{characterLimit}
            </div>
          )}
        </div>
        <Input
          readOnly={readOnly}
          disabled={disabled}
          className={inputClassName}
          {...reactHookForm.register(pathToPrimaryLang as any, registerOptions)}
          {...inputProps}
        />
      </label>
      {active &&
        __languages.map((lang) => (
          <div key={lang} className='mt-2'>
            <div className='flex gap-2 items-center'>
              <div>
                <label className={labelClassName}>{lang}</label>
              </div>
              <Input
                readOnly={readOnly}
                disabled={disabled}
                className={inputClassName}
                {...reactHookForm.register(
                  `${path}.${lang}` as any,
                  registerOptions,
                )}
                {...inputProps}
              />
            </div>
          </div>
        ))}
      {error?.message && (
        <p className={errorClassName}>{error?.message as string}</p>
      )}
    </div>
  );
}
