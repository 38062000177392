import { Fragment, ReactNode, useCallback } from 'react';
import type { FieldValues, Path } from 'react-hook-form';

import { GetArrayPaths, InputFormField } from '..';
import { InputFieldProps, InputFieldWithFormProps } from '../types';

export type DeleteElementFieldProps<Type extends FieldValues> = Omit<
  InputFieldProps<Type, typeof Fragment>,
  'path'
> & {
  collection: GetArrayPaths<Type>;
  index: number;
  render: (
    props: Pick<InputFieldProps<Type, typeof Fragment>, 'disabled'> & {
      index: number;
      onClick(): void;
    },
  ) => ReactNode;
};

export function DeleteElementField<Type extends FieldValues>(
  props: InputFieldWithFormProps<
    Type,
    typeof Fragment,
    DeleteElementFieldProps<Type>
  >,
) {
  const {
    index,
    collection,
    reactHookForm: { getValues, setValue },
    render,
  } = props;

  const handleDelete = useCallback(() => {
    const values = getValues(collection as Path<Type>);

    if (!values) {
      console.log(`couldn't delete entry ${index} at ${collection}`);
      return;
    }

    values.splice(index, 1);

    setValue(collection as Path<Type>, values, {
      shouldTouch: true,
    });
  }, [collection, getValues, index, setValue]);

  return (
    <InputFormField errorClassName='hidden' path={collection} {...props}>
      {render({ onClick: handleDelete, ...props })}
    </InputFormField>
  );
}
