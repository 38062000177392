import { ElementType, PropsWithChildren } from 'react';
import { InputFieldWithFormProps } from './types';
import { FieldValues, get } from 'react-hook-form';
import { classes } from '../classes';

export function ImagePreviewField<
  type extends FieldValues,
  ElType extends ElementType = 'img',
>({
  path,
  label,
  instruction,
  reactHookForm,
  registerOptions,
  as,
  children,
  disabled,
  readOnly,
  className,
  errorClassName = classes.error,
  inputClassName = classes.input,
  labelClassName = classes.label,
  ...inputProps
}: PropsWithChildren<InputFieldWithFormProps<type, ElType>>) {
  const error = get(reactHookForm.formState.errors, path);
  const Input = as ?? 'input';
  return (
    <div className={className}>
      <label className={labelClassName}>
        <p>{label}</p>

        <div className='opacity-80 text-xs'>{instruction}</div>
        {children ?? (
          <div className='flex flex-col gap-2 items-start'>
            <Input
              readOnly={readOnly}
              disabled={disabled}
              className={inputClassName}
              {...reactHookForm.register(path, registerOptions)}
              {...inputProps}
            />
            <img
              style={{ maxHeight: '100px' }}
              {...inputProps}
              src={reactHookForm.watch(path)}
            />
          </div>
        )}
      </label>
      {error?.message && (
        <p className={errorClassName}>{error?.message as string}</p>
      )}
    </div>
  );
}
