import { Button } from '../Button';
import { ArrayHelper } from './useFormWithInputs';

export const DeleteButton: ArrayHelper<'Delete', 'collection' | 'index'> = ({
  Delete,
  collection,
  index,
}) => {
  return (
    <Delete
      collection={collection}
      index={index}
      render={({ disabled, onClick }) => (
        <Button
          onClick={onClick}
          disabled={disabled}
          size='xsmall'
          variant='dangerous'
          width='w-fit h-8 my-1'
        >
          Delete
        </Button>
      )}
    />
  );
};
