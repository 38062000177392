import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { PropsWithChildren, ReactNode, useCallback } from 'react';

export type ModalProps = {
  actions?: ReactNode[] | ReactNode;
  description?: ReactNode;
  onClose?(): void;
  open: boolean;
  title?: ReactNode;
  width?: string;
};

export function Modal({
  actions,
  children,
  description,
  onClose,
  open,
  title,
  width = 'w-full max-w-sm',
}: PropsWithChildren<ModalProps>) {
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose} className='relative z-50'>
      <div className='fixed inset-0 flex items-center justify-center p-4'>
        <DialogPanel
          className={`flex flex-col ${width} gap-6 p-6 bg-white shadow-2xl rounded-2xl drop-shadow-2xl`}
        >
          {title && (
            <DialogTitle className='text-lg font-semibold'>{title}</DialogTitle>
          )}

          {description && <Description>{description}</Description>}

          {children && <div>{children}</div>}

          {actions && <div className='flex flex-row gap-6'>{actions}</div>}
        </DialogPanel>
      </div>
    </Dialog>
  );
}
